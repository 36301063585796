<template>
  <div class="add-owner">
    <h1 class="admin-title">{{$t('Edit Owner')}}</h1>
    <ValidationObserver ref="validator">
      <form @submit.prevent="save">
        <b-row>
          <b-col>
            <RFValidation name="Name" rules="required">
              <RFInput label="Name" v-model="owner.name"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Phone number" rules="required">
              <RFInput label="Phone number" v-model="owner.tel" is-numeric/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Email" rules="required|email">
              <RFInput label="Email" v-model="owner.email"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation name="Address" rules="required">
              <RFInput label="Address" v-model="owner.address"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Zip Code" rules="required">
              <RFInput label="Zip Code" v-model="owner.zipCode"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Place of birth" rules="required">
              <RFInput label="Place of birth" v-model="owner.placeOfBirth"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInputAutocomplete label="Country" :value="owner.country" :items="countries" v-model="owner.country"/>
          </b-col>
          <b-col>
            <RFValidation name="Birth Date" rules="required">
              <RFDatePicker label="Birth Date" v-model="owner.birthDate"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Fiscal code" rules="required|fiscalCode">
              <RFInput label="Fiscal code" v-model="owner.fiscalCode" uppercase/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation name="Account name" rules="required">
              <RFInput label="Account name" v-model="owner.accountName"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Document ID" rules="required">
              <RFInput label="Document ID" v-model="owner.documentId" uppercase/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Iban" rules="required">
              <RFInput label="Iban" v-model="owner.iban" uppercase/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation name="Bic" rules="required">
              <RFInput label="Bic" v-model="owner.bic" uppercase/>
            </RFValidation>
          </b-col>
          <b-col>
            <!-- <RFValidation name="Partita Iva" rules="required"> -->
            <RFInput label="Partita Iva" v-model="owner.piva" uppercase/>
            <!-- </RFValidation>  -->
          </b-col>
          <b-col>
            <!-- <RFValidation name="Bank Name" rules="required">  -->
            <RFInput label="Bank Name" v-model="owner.bankName" />
            <!-- </RFValidation>  -->
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <RFInput label="Bank Address" v-model="owner.bankAddress" />
          </b-col>
          <b-col cols="4">
            <RFInputAutocomplete label="Bank Country" :value="owner.bankCountry" :items="countries" v-model="owner.bankCountry"/>
          </b-col>
        </b-row>
        <b-row class="justify-content-end mt-5">
          <b-col cols="auto">
            <RFButton class="mt-3" label="Save" background="#5CB86C" v-if="!loading"/>
            <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import RFValidation from '@/components/forms/RFValidation'
import RFInput from '@/components/forms/RFInput'
import RFDatePicker from '@/components/forms/RFDatePicker'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFInputAutocomplete from '@/components/forms/RFInputAutocomplete'

import { ownerService } from '@/services/admin'
import { mapState } from 'vuex'

export default {
  name: 'AdminEditOwner',
  components: {
    RFValidation,
    RFInput,
    RFDatePicker,
    RFButton,
    RFLoader,
    RFInputAutocomplete,
  },
  data() {
    return {
      owner: null,
      loading: false,
    }
  },
  computed: {
    ...mapState({
      storedOwner: state => state.adminOwnerStore.owner,
      storedCountries: state => state.countryStore.countries,
    }),
    countries() {
      return this.storedCountries.map(country => country.name)
    }
  },
  beforeMount() {
    this.owner = { ...this.storedOwner }
  },
  methods: {
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true

        const { status } = await ownerService.updateOwner(this.owner)

        if (status === 200) {
          this.$toasted.success(this.$i18n.t(`Owner successfully updated!`))
          this.$router.push({ name: 'owner-list' })
        } else {
          this.$toasted.error(this.$i18n.t(`Error during upload owner`))
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
